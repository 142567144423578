import '../assets/styles/Header.css'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import { NavDropdown, Offcanvas} from "react-bootstrap";

import logo from '../assets/images/logos/maroon-logo.svg';
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useState} from "react";

function Header({underConstruction, showLogout = false}) {
    const [show, setShow] = useState(false);

    // Function to toggle Offcanvas
    const handleToggle = () => setShow(!show);

    // Function to hide Offcanvas when a link is clicked
    const handleLinkClick = () => setShow(false);

    return (
        <Container fluid className="header-container ">
            <Container
                className="d-flex
                justify-content-lg-center align-items-lg-end
                justify-content-between align-items-start px-0"
            >
                {!underConstruction &&
                    <Container className="d-inline-flex order-0 mt-3
                justify-content-lg-end
                justify-content-start ">
                        <Navbar sticky="top" expand="lg" className="bg-primary rounded-3 h5" collapseOnSelect>
                            <Navbar.Toggle className="text-light" aria-controls="basic-navbar-nav" onClick={handleToggle}/>
                            <Navbar.Offcanvas
                                id="basic-navbar-nav"
                                placement={"start"}
                                show={show}
                            >
                                <Offcanvas.Header className={"text-primary bg-light"} closeButton onClick={handleLinkClick}>
                                    <Offcanvas.Title className="h5 text-decoration-none" id={"offcanvasNavbarLabel"} as={Link} to={"/"} onClick={handleLinkClick}>
                                        Botanical Ink LLC
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className={"bg-primary text-primary"}>
                                    <Nav>
                                        <Nav.Link as={Link} onClick={handleLinkClick} to="/" className="m-1 text-light">Home</Nav.Link>
                                        <NavDropdown className="m-1"
                                                     title={<span className={"text-light bg-primary"}>Artists</span>}>
                                            <NavDropdown.Item as="div" className={"text-light bg-primary"}>
                                                <Nav.Link to={"/artist/erika"} className={"text-light bg-primary"}
                                                          as={Link} onClick={handleLinkClick}>
                                                    Erika Holmberg
                                                </Nav.Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div" className={"text-light bg-primary"}>
                                                <Nav.Link to={"/artist/calista"} className={"text-light bg-primary"}
                                                          as={Link} onClick={handleLinkClick}>
                                                    Calista Doran
                                                </Nav.Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Link as={Link} onClick={handleLinkClick} to="/faq" className="m-1 text-light">
                                            FAQ
                                        </Nav.Link>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Navbar>
                    </Container>
                }
                <Container
                    name="logo-container-desktop"
                    className="d-none d-lg-inline-flex justify-content-center order-1 ">
                    <Link to="/">
                        <Image
                            src={logo}
                            height="250px"
                            className="logo-style"
                            alt="Botanical Ink Logo"
                        />
                    </Link>
                </Container>
                {!underConstruction &&
                    <Container
                        className="d-inline-flex mt-3 mb-2 order-2
                    justify-content-lg-start
                    justify-content-end "
                        name="book-now-container"
                    >

                        <Button
                            className={"bg-primary btn-lg"}
                        >
                            <Link
                                to="/appointment/create"
                                className={"m-auto text-light h5"}
                                style={{textDecoration: "none"}}
                            >
                                Book Now!
                            </Link>
                        </Button>
                    </Container>
                }
            </Container>
            <Container
                name="logo-container-mobile"
                className="d-flex d-lg-none  justify-content-center  ">
                <Link to="/">
                    <Image
                        src={logo}
                        height="200px"
                        className="logo-style"
                        alt="Botanical Ink Logo"
                    />
                </Link>
            </Container>
        </Container>
    );
}


export default Header;