import {FilePond, registerPlugin} from "react-filepond";
import "filepond/dist/filepond.min.css";
import "../../assets/styles/Forms.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import axios from 'axios';
import logAxiosError from "../errors/ErrorHandling";
import {Controller, useFormContext} from "react-hook-form";
import {useState} from "react";
import React, {FormGroup, FormLabel} from "react-bootstrap";
import RequiredText from "../forms/formparts/RequiredText";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename);

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const randomString = (length) =>  {
    let ret = "";
    for (let i = 0; i < length; i++) {
        let randInt = Math.floor(Math.random() * 26);
        ret += alphabet[randInt];
    }
    return ret;
}


function ImageDrop({id, label, required, isProcessing, inputName, errors}) {
    const {
        control,
    } = useFormContext();

    const [files, setFiles] = useState([]);

    const processFile = async (fieldName, file, metadata, load, error, progress, abort) => {

        console.log("processing");
        isProcessing(true);

        let fileType = file.type;
        let filename = file.name;

        let objectKey = "";
        axios.get("https://botanical.ink/api/appointments/references/upload-url", {
            params: {
                fileType: fileType,
                filename: file.name
            }
        })
            .then(function (response) {
                let url = response.data.url;
                objectKey = response.data.objectKey;

                return axios.put(url, file, {
                    onUploadProgress: (e) => {
                        progress(false, e.loaded, e.total)
                    },
                })
            })
            .then(function (response) {
                // Set the objectKey as the file's serverId
                isProcessing(false);

                setFiles([
                    ...files,
                    {filename: filename, serverId: objectKey}
                ])

                load(objectKey);
            })
            .catch(function (processsingError) {
                logAxiosError(processsingError);

                setFiles(
                    files.filter(f => f.filename !== filename)
                )

                isProcessing(false);
                error("Error uploading image!");
            });

    }

    return (
        <FormGroup className="mb-3">
            <FormLabel>{label} {required &&
                <RequiredText isMissing={(!!errors)}/>}
            </FormLabel>
            <Controller
                name={`${id}.${inputName}`}
                control={control}
                rules={{
                    validate: {
                        notEmpty: v => {
                            return required ? (!!v && v.length !== 0) : true;
                        }
                    }
                }}
                render={({field}) => {
                    return (
                        <FilePond
                            fileRenameFunction={(file) => {
                                let randStr = randomString(4);
                                console.log(`${file.basename}_${randStr}${file.extension}`);
                                return `${file.basename}_${randStr}${file.extension}`;
                            }}
                            onprocessfiles={() => {
                                console.log("files processed");
                                field.onChange(files);
                            }}
                            onremovefile={(error, file) => {
                                console.log("file removed")
                                let newFiles = files.filter(f => f.filename !== file.filename);
                                setFiles(newFiles);
                                field.onChange(newFiles);
                            }}

                            allowMultiple={true}
                            maxFiles={10}
                            // oninit={}
                            // name={`${id}.${inputName}`} /* sets the file input name, it's filepond by default */
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            dropValidation='true'
                            server={{
                                process: processFile,
                                revert: null,
                                fetch: null,
                                remove: null,
                                restore: null
                            }}
                        />
                    )
                }}
            />
        </FormGroup>
    );
}


export default ImageDrop;