import Container from "react-bootstrap/Container";
import {useEffect, useState} from "react";
import {Outlet, useLoaderData} from "react-router-dom";
import React, {ListGroup, ListGroupItem} from "react-bootstrap";

//loader to get the appointment display info {artistFullName, tattooMinutes, touchup, reservedTime, cost, rate}
export const appointmentNewLoader = async ({params}) => {
    const response = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/details`);
    if (response.ok) {
        return await response.json();
    }
}

export default function AppointmentNew() {
    const {artistFullName, tattooMinutes, touchup, rate, reservedTime, deposit, costString} = useLoaderData();
    const [appointmentDetails, setAppointmentDetails] = useState({
        loaded: false,
        touchup: touchup,
        deposit: touchup ? 0 : deposit
    });

    useEffect(() => {
        let scriptTag = document.getElementById("clover");
        if (!scriptTag) {
            scriptTag = document.createElement("script");
            scriptTag.src = "https://checkout.clover.com/sdk.js"; // Prod
            scriptTag.async = true;
            scriptTag.id = "clover";
            scriptTag.type = "text/javascript";
            scriptTag.addEventListener("load", () => setAppointmentDetails({
                ...appointmentDetails,
                loaded: true,
            }));
            document.getElementById("root").appendChild(scriptTag);
            console.log("script tag created");
        }
    }, [appointmentDetails]);

    let cost =  tattooMinutes * (rate / 60);

    if (touchup) {
        cost = 0;
    } else if (costString) {
        cost = costString;
    }

    // useEffect to set up the clover script tag so I don't have to create it every time we load the payment component
    // that page can handle mounting the form elements

    // make sure to have the loading indicator stuff here
    return (
        <>
            <Container className="bg-secondary d-flex rounded-3 column-gap-3 row-gap-3 p-1 mb-3">
                <Container className="d-flex flex-column align-items-center text-black bg-light rounded-3 p-2">
                    <h5 className="text-black mx-auto">
                        Appointment Details
                    </h5>
                    <ListGroup>
                        <ListGroupItem><strong>Artist: </strong>{artistFullName}</ListGroupItem>
                        <ListGroupItem><strong>Estimated Length: </strong>{tattooMinutes + " minutes"}</ListGroupItem>
                        <ListGroupItem><strong>Estimated Cost: </strong> ${cost}</ListGroupItem>
                        <ListGroupItem><strong>Reserved Time: </strong>{reservedTime && <span>{reservedTime}</span>}</ListGroupItem>

                    </ListGroup>
                </Container>
            </Container>

            <Outlet context={[appointmentDetails, setAppointmentDetails]}/>

        </>


    );
}