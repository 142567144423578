import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import MonsteraLeaf from "../assets/images/monstera-leaf.svg";
import "../assets/styles/Loader.css";

export default function Loader() {
    return (
        <Container className="loader-container d-flex justify-content-center align-items-center">
            <div className="">
                <Image
                    className={"loading-spinner"}
                    src={MonsteraLeaf}
                    alt="loading spinner..."
                />
            </div>
        </Container>
    );
}