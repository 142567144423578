import {FormProvider, useForm} from "react-hook-form";
import ClientDetails from "./formparts/ClientDetails";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {ListGroup, ListGroupItem, Spinner} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useState} from "react";
import ArtistSelect from "./formparts/ArtistSelect";
import TattooDetails from "./formparts/TattooDetails";
import axios from "axios";
import {json, useLoaderData, useNavigate, useNavigation} from "react-router-dom";
import Over18Checkbox from "./formparts/Over18Checkbox";

const primaryClientId = "primaryClient";

const backendRoot = "https://botanical.ink/api/appointments";

export const createLoader = async ({params, request}) => {
    const url = new URL(request.url);
    const initialArtist = url.searchParams.get("artist");

    try {
        const dataResponse = await fetch(`https://botanical.ink/api/appointments/artist-settings`);
        if (dataResponse.ok) {
            console.log("loaded artist settings");
            let dataJson = await dataResponse.json();
            console.log(dataJson);
            return json({
                ...dataJson,
                message: initialArtist,
            })
        } else {
            console.log("failed to load artist settings");
            return json({
                initialArtist: initialArtist,
            });
        }
    } catch (e) {
        console.log("failed to load artist settings: " + e);
        return json({
            initialArtist: initialArtist,
        });
    }
}

export const createAction = async ({params, request}) => {
    // POST and redirect to schedule page
}

export default function AppointmentCreate() {
    const methods = useForm();
    const [myFormState, setMyFormState] = useState({
        submitting: false,
        submitted: false,
        hasProcessingFiles: false,
        serverErrors: null,
    });

    const {formState: {isSubmitted, isValid}} = methods;

    const {
        // initialArtist,
        erika,
        calista} = useLoaderData();

    const [selectedArtist, setSelectedArtist] = useState(null);

    const navigate = useNavigate();
    const navigation = useNavigation();

    const onSubmit = (data) => {
        setSubmitting(true);

        if (!data.artist) {
            data.artist = selectedArtist;
        }

        console.log(JSON.stringify(data));

        axios.post(backendRoot, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            setServerErrors(null);
            let appointmentId = response.data.appointmentId;
            navigate("/appointment/" + appointmentId + "/schedule");
        }).catch(function (error) {
            if (error.response && error.response.data.errorType) {
                setServerErrors(error.response.data);
            } else {
                setServerErrors({
                    errorType: "UNKNOWN_ERROR",
                    message: "We encountered a problem processing your request. Please try again later."
                })
            }
            console.log(myFormState);
            setSubmitting(false);
            console.log(error.toJSON());
        })
    }

    const setProcessingFiles = (value) => setMyFormState(prevState => ({
        ...prevState,
        hasProcessingFiles: value
    }));

    const setSubmitting = (value) => setMyFormState(prevState => ({
        ...prevState,
        submitting: value,
        submitted: true,
    }));

    const setServerErrors = (errors) => setMyFormState(prevState => ({
        ...prevState,
        serverErrors: errors
    }));


    return (
        <FormProvider {...methods} >
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <Container className="bg-success mb-3 p-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
                    <Container className="d-flex flex-column align-items-center text-black bg-light rounded-3 p-2">
                        <p className="h5">Thank you for choosing us! Before you begin, please take a moment to read
                            through these important notes:</p>
                        <ListGroup>
                            <ListGroupItem>
                                <strong>Non-Refundable Deposit:</strong> Every tattoo appointment will require a
                                non-refundable deposit at the time of booking.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Deposit Deduction:</strong> The deposit amount will be deducted from the total
                                cost of your tattoo at the end of your appointment.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Rescheduling Flexibilty:</strong> Life happens! If you need to reschedule your
                                appointment, your deposit will seamlessly transfer to your new appointment date.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>72-Hour Notice:</strong> Appointments may not be rescheduled within 72 hours of
                                the scheduled time.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Deposit Expiration:</strong> Unfortunately, we cannot hold on to your
                                deposit indefinitely. If your appointment does not occur within one year of booking,
                                the deposit will be considered forfeit.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Touch-ups:</strong> In order to ensure you love your tattoo for years to come,
                                our
                                artists offer periodic touch-ups free of charge. Touch-up appointments do not require a
                                deposit.
                            </ListGroupItem>
                        </ListGroup>
                    </Container>
                </Container>
                <Container className="bg-success text-light mb-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
                    <div key="artistTitle" className={"h1 mx-auto my-2"}>
                        Artist Selection
                    </div>
                    <ArtistSelect setSelectedArtist={setSelectedArtist}/>
                </Container>
                {selectedArtist &&
                    <>

                        <Container
                            className="bg-success d-flex flex-column text-light mb-3 rounded-3 p-2"
                        >
                            <Container className={""}><h1>About You</h1></Container>
                            <ClientDetails clientType="primaryClient"/>
                            {/*<ClientDetails clientType="secondaryClient"/>*/}
                        </Container>

                        <TattooDetails
                            id={0}
                            clientId={primaryClientId}
                            artistSettings={selectedArtist === "erika" ? erika : calista}
                            isProcessing={setProcessingFiles}
                            artistName={selectedArtist}
                        />

                        <Container className="bg-success text-light mb-3 rounded-3 p-3">

                            {isSubmitted && !isValid &&
                                <p>One or more inputs is invalid. Please correct them and try again</p>
                            }

                            {myFormState.submitted && myFormState.serverErrors &&
                                <p>{myFormState.serverErrors.message}</p>
                            }

                            <Over18Checkbox/>
                            <div className={"d-grid"}>
                                <Button variant="primary" type="submit" className="d-grid"
                                        disabled={(myFormState.submitting || myFormState.hasProcessingFiles)}
                                >
                                    {(myFormState.submitting || myFormState.hasProcessingFiles || navigation.state !== "idle")
                                        ?
                                        <span>
                                            <Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>
                                            {myFormState.hasProcessingFiles
                                                ? <span>Uploading</span>
                                                : <span>Submitting</span>}
                                        </span>
                                        :
                                        <span>Next</span>
                                    }
                                </Button>
                            </div>
                        </Container>
                    </>
                }
            </Form>
        </FormProvider>
    );

}