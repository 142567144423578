import {FormProvider, useForm} from "react-hook-form";
import ClientDetailsV2 from "./ClientDetailsV2";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {
    Collapse,
    ListGroup,
    ListGroupItem,
    Spinner
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useState} from "react";
import ArtistSelect from "../formparts/ArtistSelect";
import {json, redirect, useActionData, useLoaderData, useNavigation, useSubmit} from "react-router-dom";
import Over18Checkbox from "../formparts/Over18Checkbox";
import TattooDetailsV2 from "./TattooDetailsV2";
import {ReactComponent as ExpandIcon} from "../../../assets/images/icons/chevron-up-svgrepo-com.svg";
import {ReactComponent as CollapseIcon} from "../../../assets/images/icons/chevron-down-svgrepo-com.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/x-svgrepo-com.svg";
import {doJsonPOST} from "../../util/FetchUtils";

const postPath = "appointments/v2";

export const createLoaderV2 = async ({request}) => {
    const url = new URL(request.url);
    const initialArtist = url.searchParams.get("artist");

    try {
        const dataResponse = await fetch(`https://botanical.ink/api/appointments/artist-settings`);
        if (dataResponse.ok) {
            console.log("loaded artist settings");
            let dataJson = await dataResponse.json();
            return json({
                settings: dataJson,
                initialArtist: initialArtist,
                error: null
            })
        } else {
            console.log("failed to load artist settings");
            return json({
                error: "failed to load artist settings",
            });
        }
    } catch (e) {
        console.log("failed to load artist settings: " + e);
        return json({
            error: "failed to load artist settings" + e,
        });
    }
}

export const createActionV2 = async ({params, request}) => {
    // POST and redirect to schedule page
    let jsonData = await request.json();

    try {
        const response = await doJsonPOST(postPath, jsonData);
        let responseData = await response.json();
        console.log(responseData);
        if (response.ok) {
            // redirect to next step
            return redirect(`/appointment/${responseData.aid}/schedule`);
        } else if (response.status === 400) {
            // appointment validation issue, send error message in json back to action

            let message = responseData.message;

            return json({
                error: {
                    status: response.status,
                    message: message ? message : "problem"
                }
            })
        } else {
            return json({
                error: {
                    status: response.status,
                    message: "An error occurred while making a request to the server"
                }
            })
        }
    } catch (error) {
        return json({
            error: {
                status: 500,
                message: "An error occurred while making a request to the server"
            }
        })
    }
}

export default function AppointmentCreateV2() {
    const methods = useForm();
    const [myFormState, setMyFormState] = useState({
        submitting: false,
        submitted: false, //means the user attempted to submit, not actual success
        hasProcessingFiles: false,
        serverErrors: null,
    });

    const {formState: {isSubmitted, isValid}} = methods;

    const loaderData = useLoaderData();

    const actionData = useActionData();

    const submit = useSubmit();

    const [selectedArtist, setSelectedArtist] = useState(null);
    const [artistSettings, setArtistSettings] = useState(null);
    // This is to show or hide the disclaimer at the top
    const [notesOpen, setNotesOpen] = useState(true);
    const [hasBuddy, setHasBuddy] = useState(false);

    const navigation = useNavigation();

    const onSubmit = (data) => {
        setSubmitting(true);

        if (!data.artist) {
            data.artist = selectedArtist;
        }

        console.log(JSON.stringify(data));

        submit(data, {
            method: "post",
            encType: "application/json",
        });
    }

    const setProcessingFiles = (value) => setMyFormState(prevState => ({
        ...prevState,
        hasProcessingFiles: value
    }));

    const setSubmitting = (value) => setMyFormState(prevState => ({
        ...prevState,
        submitting: value,
        submitted: true,
    }));

    // const setServerErrors = (errors) => setMyFormState(prevState => ({
    //     ...prevState,
    //     serverErrors: errors
    // }));

    const toggleBuddy = () => {
        setHasBuddy(!hasBuddy);
    }

    const changeSelectedArtist = (artistName) => {
        setSelectedArtist(artistName);
        setArtistSettings(artistName === "erika" ? loaderData.settings.erika : loaderData.settings.calista);
    }

    return (
        <FormProvider {...methods} >
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <Container className="bg-success mb-3 p-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
                    <Button
                        className="bg-light text-black mb-0 d-flex"
                        onClick={() => setNotesOpen(!notesOpen)}
                        aria-controls="notes-collapse-text"
                        aria-expanded={notesOpen}
                    >
                        <span className="flex-grow-1 h5">

                            Thank you for choosing us! Before you begin, please take a moment to read
                                through these important notes:
                        </span>
                        {notesOpen
                            ? <CollapseIcon fill="black" className="mx-2" style={{height: "50px", width: "50px"}}/>
                            : <ExpandIcon fill="black" className="mx-2" style={{height: "50px", width: "50px"}}/>
                        }

                    </Button>
                    <Collapse in={notesOpen}>
                        <ListGroup>
                            <ListGroupItem>
                                <strong>Non-Refundable Deposit:</strong> Every tattoo appointment will require a
                                non-refundable deposit at the time of booking.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Deposit Deduction:</strong> The deposit amount will be deducted from the
                                total
                                cost of your tattoo at the end of your appointment.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Rescheduling Flexibilty:</strong> Life happens! If you need to reschedule
                                your
                                appointment, your deposit will seamlessly transfer to your new appointment date.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>72-Hour Notice:</strong> Appointments may not be rescheduled within 72 hours
                                of
                                the scheduled time.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Deposit Expiration:</strong> Unfortunately, we cannot hold on to your
                                deposit indefinitely. If your appointment does not occur within one year of booking,
                                the deposit will be considered forfeit.
                            </ListGroupItem>
                            <ListGroupItem>
                                <strong>Touch-ups:</strong> In order to ensure you love your tattoo for years to
                                come,
                                our
                                artists offer periodic touch-ups free of charge. Touch-up appointments do not
                                require a
                                deposit.
                            </ListGroupItem>
                        </ListGroup>
                    </Collapse>
                </Container>

                <Container className="bg-success text-light mb-3 rounded-3 d-flex flex-column column-gap-1 row-gap-1">
                    <div key="artistTitle" className={"h1 mx-auto my-2"}>
                        Artist Selection
                    </div>
                    <ArtistSelect setSelectedArtist={changeSelectedArtist}/>
                </Container>
                {selectedArtist &&
                    <>
                        <Container
                            className="bg-success d-flex flex-column text-light mb-3 rounded-3 p-2"
                        >
                            <Container className={""}><h1>About You</h1></Container>
                            <AppointmentDetails
                                clientId={0}
                                artistSettings={artistSettings}
                                isProcessing={setProcessingFiles}
                                artistName={selectedArtist}
                            />
                            <Container>

                                <Container className={"d-flex justify-content-center justify-content-lg-start"}>
                                    <Button
                                        className={"mb-3 flex-grow-1 flex-lg-grow-0"}
                                        type={"button"}
                                        onClick={toggleBuddy}
                                        disabled={hasBuddy}
                                        style={{minWidth: "300px"}}
                                    >
                                        {/*{hasBuddy ? "Book for Just Me" : "Book With a Friend"}*/}
                                        + Add Another Person
                                    </Button>
                                </Container>
                                {hasBuddy &&
                                    <span className={"small"}>Maximum of two people per appointment</span>
                                }
                            </Container>
                        </Container>

                        {/* buddy container */}
                        {hasBuddy &&
                            <Container
                                className="bg-success d-flex flex-column text-light mb-3 rounded-3 p-2"
                            >
                                <Container
                                    className="bg-success d-flex flex-column text-light rounded-3 p-2"
                                >
                                    <Button onClick={toggleBuddy}
                                            className="w-auto me-auto"
                                            aria-label="Remove Buddy">
                                        <CloseIcon fill="white" style={{height: "25px", width: "25px"}}/>
                                    </Button>

                                    <Container className={""}><h1>About Your Buddy</h1></Container>
                                    <AppointmentDetails
                                        clientId={1}
                                        artistSettings={artistSettings}
                                        isProcessing={setProcessingFiles}
                                        artistName={selectedArtist}
                                    />
                                </Container>
                            </Container>
                        }

                        <Container className="bg-success text-light mb-3 rounded-3 p-3">

                            {isSubmitted && !isValid &&
                                <p>One or more inputs is invalid. Please correct them and try again</p>
                            }

                            {isSubmitted && actionData && actionData.error &&
                                <p>{actionData.error.message}</p>
                            }

                            <Over18Checkbox hasBuddy={hasBuddy}/>
                            <Container className={"d-grid"}>
                                <Button variant="primary" type="submit" className="d-grid"
                                        disabled={(myFormState.submitting || myFormState.hasProcessingFiles)}
                                >
                                    {(myFormState.hasProcessingFiles || navigation.state !== "idle")
                                        ?
                                        <span>
                                            <Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>
                                            {myFormState.hasProcessingFiles
                                                ? <span>Uploading</span>
                                                : <span>Submitting</span>}
                                        </span>
                                        :
                                        <span>Next</span>
                                    }
                                </Button>
                            </Container>
                        </Container>
                    </>
                }
            </Form>
        </FormProvider>
    );

}

// couldn't come up with a better name for something to wrap ClientDetails and TattooDetails inside...
export function AppointmentDetails({
                                       clientId = 0,
                                       artistSettings,
                                       isProcessing,
                                       artistName,
                                   }) {

    const [additionalTattoos, setAdditionalTattoos] = useState([]);
    const [nextTattooId, setNextTattooId] = useState(1)
    const defaultTattooId = 0;

    const addTattoo = () => {
        setAdditionalTattoos(
            [
                ...additionalTattoos,
                {id: nextTattooId}
            ]
        )

        setNextTattooId(nextTattooId + 1);
    }

    const removeTattoo = (id) => {
        setAdditionalTattoos(
            additionalTattoos.filter(tattoo => tattoo.id !== id)
        );
    }
    let displayOrder = 2;
    return (
        <>
            <ClientDetailsV2 clientId={clientId}/>
            <Container>
                <TattooDetailsV2
                    id={defaultTattooId}
                    clientId={clientId}
                    artistSettings={artistSettings}
                    isProcessing={isProcessing}
                    artistName={artistName}
                />
                {additionalTattoos.map((tattoo) => (
                    <div key={"div." + tattoo.id}>
                        <Button onClick={() => removeTattoo(tattoo.id)}
                                key={"closeButton." + tattoo.id}
                                className="w-auto me-auto"
                                aria-label="Remove Tattoo">
                            <CloseIcon fill="white" style={{height: "25px", width: "25px"}}/>
                        </Button>
                        <TattooDetailsV2
                            key={"tattoo." + tattoo.id}
                            id={tattoo.id}
                            clientId={clientId}
                            displayOrder={displayOrder++}
                            artistSettings={artistSettings}
                            isProcessing={isProcessing}
                            artistName={artistName}
                        />
                    </div>
                ))

                }
                <Container className={"justify-content-center justify-content-lg-start d-flex"}>
                    <Button
                        className={"mb-3 flex-grow-1 flex-lg-grow-0"}
                        onClick={() => addTattoo()}
                        disabled={additionalTattoos.length === 3} //max 4 total tattoos
                        style={{minWidth: "300px"}}
                    >
                        + Add Another Tattoo
                    </Button>
                </Container>
            </Container>
        </>
    );
}