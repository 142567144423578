
let basePath = "https://botanical.ink/api/";

export const doJsonPOST = async (path, jsonData, optionalHeaders = {}) => {

    let jsonHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestInit = {
        method: "post",
        headers: { ...jsonHeaders,  ...optionalHeaders},
        body: JSON.stringify(jsonData),
    };

    return fetch(basePath + path, requestInit);

}

export const doJsonGET = async ({path, jsonData, optionalHeaders = {}}) => {

    let jsonHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestInit = {
        headers: { ...jsonHeaders,  ...optionalHeaders},
    };

    return fetch(basePath + path, requestInit);

}