import {isRouteErrorResponse, useRouteError} from "react-router-dom";


export default function ErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>An appointment with that ID does not exist</div>;
        }

        if (error.status === 401) {
            return <div>You aren't authorized to see this</div>;
        }

        if (error.status === 503) {
            return <div>Looks like our API is down</div>;
        }

        if (error.status === 418) {
            return <div>wut?</div>;
        }

        return <div>Something went kinda wrong</div>;

    }

    return <div>Something went wrong</div>;
}