import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import LoginConfig from "./LoginConfig";

const userPool = new CognitoUserPool(LoginConfig.poolData);
const AuthContext = createContext(null);

const defaultUserData = {
    authenticated: false,
    requiresReset: false,
    username: ""
}

export const AuthProvider = ({children}) => {
    const [userData, setUserData] = useState(defaultUserData);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    async function doLogin(username, password) {
        let authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });

        let cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (session) => {
                console.log('User authenticated. CognitoUser: ', cognitoUser);

                setUserData({
                    ...userData,
                    authenticated: true,
                    username: username,
                    requiresReset: false
                });
                navigate("/admin");
            },
            onFailure: (error) => {
                console.error('Authentication failed:', error);
                navigate("login");
                // Handle authentication failure, display error messages, etc.
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                setUserData({
                    ...userData,
                    authenticated: false,
                    username: username,
                    requiresReset: true
                });
                navigate("login");
            },
        });

    }

    async function doLogout() {
        setUserData(defaultUserData);
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            try {
                await cognitoUser.signOut();
            } catch (error) {
                console.error(error);
            }
        }
        navigate("login");
    }

    async function setNewPassword(newPassword) {
        let currentUser = userPool.getCurrentUser();
        currentUser.completeNewPasswordChallenge(newPassword, null, {
            onSuccess: session => {
                console.log("successfully set new password");
                setUserData({
                    ...userData,
                    authenticated: true,
                    username: currentUser.getUsername(),
                    requiresReset: false
                });
            },
            onFailure: err => {
                console.log(err);
            }
        })
    }

    async function getIdToken() {
        try {
            const result = await getSession();
            return result.getIdToken();
        } catch (error) {
            console.error(error); // Logs "Operation failed." if rejected
            return null;
        }
    }

    async function getSession() {
        let currentUser = userPool.getCurrentUser();
        return new Promise(function (resolve, reject) {
            if (!currentUser) {
                reject("No current user");
            }
            currentUser.getSession(function (err, session) {
                if (err) {
                    reject(err)
                } else {
                    resolve(session)
                }
            })
        }).catch((err) => {
            throw err
        })
    }

    useEffect(() => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            // getSession refreshes the tokens if it can!
            currentUser.getSession((err, session) => {
                setLoading(false);
                if (err) {
                    console.error(err);
                    return;
                }
                setUserData({
                    ...userData,
                    authenticated: true,
                    username: currentUser.getUsername(),
                    requiresReset: false
                })
            });
        } else {
            setUserData(defaultUserData);
            setLoading(false);
        }
    }, []);

    const value = {userData, loading, getIdToken, doLogin, doLogout, setNewPassword};
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
    return useContext(AuthContext)
};