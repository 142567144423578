import {Toast, ToastBody, ToastContainer, ToastHeader} from "react-bootstrap";


const toastMinWidth = "300px";

export function SuccessToast({show, onClose, delay = 5000, header = "Success!", children}) {
    return (
        <ToastContainer
            className="position-fixed rounded-3 p-3"
            position="bottom-center"
            style={{
                zIndex: 99
            }}
        >
            <Toast
                className="text-light rounded-3 w-auto z-3"
                bg="success"
                show={show}
                onClose={onClose}
                delay={delay}
                autohide={delay !== 0}
                style={{
                    minWidth: toastMinWidth,
                    zIndex: 999
                }}
            >
                <ToastHeader className="d-flex justify-content-between rounded-top-3 bg-dark bg-opacity-50 p-2"
                             closeVariant="white">
                    <strong className="ms-1">{header}</strong>
                </ToastHeader>
                <ToastBody className="d-flex p-3 justify-content-center">{children}</ToastBody>
            </Toast>
        </ToastContainer>
    );
}