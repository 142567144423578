import Container from "react-bootstrap/Container";
import {useAuthContext} from "../auth/AuthContext";
import {useState} from "react";
import Button from "react-bootstrap/Button";
import {useLoaderData} from "react-router-dom";

export const adminLoader = (tokenFunction) => async ({ params, request }) => {
    console.log("Holy fuck it worked??");

    let token = await tokenFunction();
    console.log(token);
    return {};
}

export const ArtistHome = () => {
    const data = useLoaderData();
    return (
        <Container className="bg-success-subtle d-flex flex-column rounded-3 mb-3">
            <h1>Hello</h1>

        </Container>
    );
}