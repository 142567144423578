import "../assets/styles/global.css";
import Container from "react-bootstrap/Container";
import {FaqContent} from "./FaqContent";

export default function FAQ() {
    return (
        <Container fluid className={"background-container d-flex flex-column flex-grow-1"}>
            <Container className={"bg-success d-flex flex-column text-light rounded-3 mb-3"}>
                <h1 className={"flex-row"}>Frequently Asked Questions</h1>
            </Container>
            <Container>
                <FaqContent/>
            </Container>
        </Container>
    );
}