import {useFormContext, useWatch} from "react-hook-form";
import Container from "react-bootstrap/Container";
import React, {FormControl, FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import ImageDrop from "../../util/ImageDrop";
import RequiredText from "./RequiredText";


function getDescriptionPlaceholder(type) {
    if (type === "custom") return "Please provide a detailed description of the tattoo design.";
    if (type === "flash") return "Following the artist's guidance, provide the name, number, or ID of the flash piece you would like";
    if (type === "touchup") return "Briefly describe what needs touching up.";
    return "";
}

function getImageDropLabel(type) {
    if (type === "custom") return "Reference Images";
    if (type === "flash") return "";
    if (type === "touchup") return "Recent Picture of the Tattoo";
    return "";
}

export default function TattooDetails({
                                          id = 0,
                                          clientId,
                                          artistSettings,
                                          isProcessing,
                                          artistName,
                                      }) {
    const {
        register,
        control,
        formState: {
            errors,
        }
    } = useFormContext();

    const fullId = `tattooDetails.tattoo${id}`;

    const watchTattooType = useWatch({
        control,
        name: `${fullId}.tattooType`,
        defaultValue: "custom"
    });

    const watchTattooStyle = useWatch({
        control,
        name: `${fullId}.tattooStyle`,
        defaultValue: "line"
    });

    const watchLength = useWatch({
        control,
        name: `${fullId}.length`,
        defaultValue: 0
    });

    const watchWidth = useWatch({
        control,
        name: `${fullId}.width`,
        defaultValue: 0
    });

    const watchFirstName = useWatch({
        control,
        name: `${clientId}.firstName`,
        defaultValue: "",
    });

    const {
        // depositAmount,
        doesColor,
        hasFlash,
        // maxAreaColor,
        maxAreaLine,
        maxAreaShade,
        // maxHoursPerAppointment,
        // rateCustom,
        // rateFlash
    } = artistSettings;

    const getError = (name) => {
        if (errors["tattooDetails"] && errors["tattooDetails"][`tattoo${id}`]
            && errors["tattooDetails"][`tattoo${id}`]) {
            return errors["tattooDetails"][`tattoo${id}`][name];
        }
    }

    return (
        <Container className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-2">
            <Container>
                {watchFirstName && watchFirstName !== ""
                    ? <h1>{watchFirstName}'s Tattoo</h1>
                    : <h1>Your Tattoo</h1>
                }

            </Container>
            <Container className="w-75">
                <FormGroup className="mb-3 d-flex flex-column flex-grow-1 align-items-center">
                    <FormLabel className="mb-1">
                        Type of Tattoo <RequiredText isMissing={!!getError("description")}/>
                    </FormLabel>
                    <FormSelect
                        name="tattooType"
                        {...register(`${fullId}.tattooType`, {
                            required: true
                        })}
                        defaultValue="custom"
                    >
                        <option value="custom">Custom Tattoo</option>
                        {hasFlash && <option value="flash">Pre-drawn Flash</option>}
                        <option value="touchup">Touch Up</option>
                    </FormSelect>
                </FormGroup>

            </Container>
            {watchTattooType === "custom" &&
                <Container>
                    <FormGroup className="mb-3 d-flex flex-column flex-grow-1">
                        <FormLabel className="mb-1">
                            Line-work, Shaded, or Color? <RequiredText
                            isMissing={!!getError("tattooStyle")}/>
                        </FormLabel>
                        <FormSelect
                            name="tattooStyle"
                            {...register(`${fullId}.tattooStyle`, {
                                required: true
                            })}
                            defaultValue=""
                        >
                            <option value="" disabled>Select Style</option>
                            <option
                                value="line">Line-work{artistName === "calista" ? " (black and grey)" : ""}</option>
                            <option value="shaded">Shaded{artistName === "calista" ? " (black and grey)" : ""}</option>
                            {doesColor &&
                                <option value="color">Color</option>
                            }
                        </FormSelect>
                    </FormGroup>
                </Container>
            }
            {watchTattooType === "custom" &&
                <>
                    <Container id="length-and-width" className={"d-flex flex-row column-gap-3"}>
                        <FormGroup className="mb-3 flex-grow-1" controlId="length">
                            <FormLabel className="mb-1">Length <RequiredText
                                isMissing={!!getError("length")}/></FormLabel>
                            <FormControl type="number"
                                         placeholder="Length in inches"
                                         {...register(`${fullId}.length`, {
                                             shouldUnregister: true,
                                             required: true,
                                             min: {
                                                 value: 1,
                                                 message: "Minimum: 1"
                                             },
                                             max: {
                                                 value: 20,
                                                 message: "Maximum: 20"
                                             },
                                         })}
                                         isInvalid={!!getError("length")}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3 flex-grow-1" controlId="width">
                            <FormLabel className="mb-1">Width <RequiredText
                                isMissing={!!getError("width")}/></FormLabel>
                            <FormControl type="number"
                                         placeholder="Width in inches"
                                         {...register(`${fullId}.width`, {
                                             shouldUnregister: true,
                                             required: true,
                                             min: {
                                                 value: 1,
                                                 message: "Minimum: 1"
                                             },
                                             max: {
                                                 value: 20,
                                                 message: "Maximum: 20"
                                             },
                                         })}
                                         isInvalid={!!getError("width")}
                            />
                        </FormGroup>

                    </Container>
                    <Container>
                        <FormGroup className="mb-3 flex-grow-1" controlId="area">
                            <FormLabel className="mb-1">Area <span style={{opacity: "0.7", fontSize: "small"}}>
                                (calculated)</span></FormLabel>
                            <FormControl type="number"
                                         value={watchLength * watchWidth}
                                         readOnly={true}
                                         aria-readonly={true}
                                         placeholder="Area"
                                         {...register(`${fullId}.area`, {
                                             shouldUnregister: true,
                                             min: {
                                                 value: 0,
                                                 message: "LOW"
                                             },
                                             max: {
                                                 value: watchTattooStyle === "line"
                                                     ? maxAreaLine
                                                     : maxAreaShade,
                                                 message: `Maximum size for the selected artist and style is 
                                                 ${watchTattooStyle === "line"
                                                     ? maxAreaLine
                                                     : maxAreaShade} square inches`
                                             },

                                         })}
                                         isInvalid={!!getError("area")}
                                         style={{
                                             backgroundColor: "lightgray",
                                         }}
                            />
                        </FormGroup>
                    </Container>
                    <Container>
                        {getError("area") &&
                            <p style={{color: "red"}}>{getError("area").message}</p>
                        }
                    </Container>
                </>
            }
            <Container>
                <FormGroup className="mb-3" controlId="description">
                    <FormLabel>Description <RequiredText
                        isMissing={!!getError("description")}/></FormLabel>
                    <FormControl as="textarea" rows={5}
                                 placeholder={getDescriptionPlaceholder(watchTattooType)}
                                 {...register(`${fullId}.description`, {
                                     required: true
                                 })}
                                 isInvalid={!!getError("description")}
                    />
                </FormGroup>
                {(watchTattooType === "custom" || watchTattooType === "flash") &&
                    <FormGroup className="mb-3" controlId="placement">
                        <FormLabel>Tattoo Placement <RequiredText isMissing={!!getError("placement")}/></FormLabel>
                        <FormControl as="textarea" rows={5}
                                     placeholder="Please describe the exact placement for your new tattoo.
                                      Example: On the top of my left forearm, etc"
                                     {...register(`${fullId}.placement`, {
                                         required: 'fartfart'
                                     })}
                                     isInvalid={!!getError("placement")}
                        />
                    </FormGroup>
                }
                {watchTattooType !== "flash" &&
                    <ImageDrop
                        id={fullId}
                        label={getImageDropLabel(watchTattooType)}
                        required={watchTattooType !== "flash"}
                        isProcessing={isProcessing}
                        inputName="files"
                        errors={getError("files")}
                    />
                }
            </Container>
        </Container>
    );
}