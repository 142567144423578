import React, {useState} from "react";
import {
    json,
    redirect,
    useActionData,
    useLoaderData,
    useNavigation,
    useOutletContext,
    useParams,
    useSubmit
} from "react-router-dom";

import TimePicker from "./formparts/TimePicker";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";


export const scheduleLoaderV2 = async ({params, request}) => {
    const url = new URL(request.url);
    const message = url.searchParams.get("message");

    const dataResponse = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/availability`);
    if (dataResponse.ok) {
        let dataJson = await dataResponse.json();
        return json({
            ...dataJson,
            message: message,
        })
    } else {
        throw dataResponse
    }
}

export const scheduleAction = async ({params, request}) => {
    console.log("schedule action hit");

    let jsonData = await request.json();

    let response = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/reservation`,
        {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData),
        });
    if (response.status === 200) {
        if (jsonData.deposit === 0) {
            try {

                let finalizeResponse = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/finalize`,
                    {
                        method: "post",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(jsonData),

                    });
                return redirect(`/appointment/${params.appointmentId}/confirmed`);
            } catch (e) {
                return json({
                    error: "SERVER_ERROR",
                })
            }
        } else {
            return redirect(`/appointment/${params.appointmentId}/payment`);
        }
    } else if (response.status === 422) {
        return json({
            error: "TIME_UNAVAILABLE",
        });
    } else {
        return json({
            error: "SERVER_ERROR",
        })
    }
}

export default function AppointmentSchedule() {

    const loaderData = useLoaderData(); // gets the schedule data

    let actionData = useActionData();
    const submit = useSubmit();

    let {appointmentId} = useParams();
    const [appointmentDetails, setAppointmentDetails] = useOutletContext();

    const [selectedTime, setSelectedTime] = useState();

    const navigation = useNavigation();

    const onSubmit = () => {
        console.log("Submitting selectedTime");

        setAppointmentDetails({
            ...appointmentDetails,
            selectedTime: selectedTime
        })

        submit({
            appointmentId: appointmentId,
            startDateTime: selectedTime,
            deposit: appointmentDetails.deposit
        }, {
            method: "post",
            encType: "application/json",
        });
    }

    return (
        <>
            {actionData && actionData.error &&
                <p className="mb-0 mt-3" style={{color: "red"}}>
                    {actionData.error === "TIME_UNAVAILABLE"
                        ? "The time you had selected is no longer available. Please select a new time from the updated calendar."
                        : "An error occurred on the server while reserving the selected time. Please try again later."}
                </p>
            }
            <TimePicker
                closed={loaderData.closed}
                rawDates={loaderData.dates}
                dateTimeObj={loaderData.dateTimeMap}
                tattooMinutes={loaderData.tattooMinutes}
                timeSelectedCallback={setSelectedTime}
            />
            <Container
                className="bg-success d-flex flex-column justify-content-center text-light my-3 rounded-3 p-3">
                <Button
                    className="w-50 mx-auto"
                    disabled={!selectedTime || navigation.state !== "idle"}
                    onClick={onSubmit}
                >
                    Next
                </Button>
            </Container>
        </>
    );
}