import Container from "react-bootstrap/Container";
import {FaInstagram} from "react-icons/fa";
import Image from "react-bootstrap/Image";
import shopPic from "../../assets/images/billc-shop-floor.jpg";

export default function UnderConstruction() {
    return (
        <Container fluid className={"background-container d-flex flex-column flex-grow-1"}>
            <Container className={"bg-success d-flex flex-column flex-grow-1 text-light rounded-3"}>
                <h1 className={"mx-auto"}>New website coming soon!</h1>
                <div className={"mx-auto"}>
                    <p>
                        Welcome to Botanical Ink, your future destination for fine-line tattoos in the heart of
                        downtown Boise!
                        The shop is ready to go, but our developers need a bit more time to
                        put the finishing touches on the website and new booking system. We expect to open our
                        doors in the third week of January, but be sure to follow us on Instagram where we will make
                        the official announcement that the site is live and accepting new bookings.
                    </p>
                    <p>
                        We are grateful for your love and support during this transition period and we can't wait
                        to share this next chapter with all of you. See you soon!
                    </p>
                    <p></p>
                </div>
                <a className={"mx-auto mb-3 text-light"} href={"https://www.instagram.com/botanicalink"}>
                    <FaInstagram size={"40px"} className={"mx-2"}/>
                    Botanical Ink Instagram
                </a>
            </Container>
        </Container>
    );
}

export function TempHome() {
    return (
        <Container fluid className={"background-container d-flex flex-column flex-grow-1"}>
            <Container className={"bg-success d-flex flex-column flex-grow-1 text-light rounded-3 p-3"}>
                <h1 className={"mx-auto"}>We're Open!</h1>
                <div className={"mx-auto"}>
                    <p>
                        It's been a long wait and a wild ride, but we are finally ready open things up!
                    </p>
                    <p>
                        Our booking form is available through the "Book Now" button above, and our FAQ page is packed with
                        information and guidance should you need it. Some parts of the site are still under construction,
                        so we apologize if you experience any hickups as we work to iron things out.
                    </p>
                    <p>
                        Thanks again for your patience and support. See you soon!
                    </p>
                </div>
                <Image
                    className=""
                    src={shopPic}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                    alt="Picture of the shop"
                />
            </Container>
        </Container>
    );
}