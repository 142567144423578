import '../assets/styles/App.css';
import {useOutlet} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Container from "react-bootstrap/Container";

export default function PublicLayout() {
    const outlet = useOutlet();
    const underConstruction = false;
    return (

        <Container fluid className={"vh-100 px-0 d-flex flex-column"}>
            <Header underConstruction={underConstruction}/>
            <Container fluid className={"background-container d-flex flex-column flex-grow-1 my-3"}>
                {outlet}
            </Container>

            <Footer/>
        </Container>

    );
}
