import {FormProvider, useForm} from "react-hook-form";

import ResetForm from "./ResetForm";
import LoginForm from "./LoginForm";
import {useAuthContext} from "./AuthContext";
import React from "react-bootstrap";
import LogoutForm from "./LogoutForm";


export default function AdminLogin() {
    const auth = useAuthContext();
    const methods = useForm({});

    const loginUser = async (data) => {
        let {username, password} = data;

        await auth.doLogin(username, password);
    };

    const handleNewPassword = async (event) => {
        console.log("handling new password");
        let newPassword = event.newPassword;
        await auth.setNewPassword(newPassword);
    };

    const logoutUser = async () => {
        await auth.doLogout();
    };

    return (
        <div>

            <FormProvider {...methods} >
                {auth.userData && auth.userData.authenticated
                    ?
                    auth.userData.requiresReset
                        ? <ResetForm onSubmit={methods.handleSubmit(handleNewPassword)}/>
                        : <LogoutForm username={auth.userData.username} action={logoutUser}/>
                    :
                    <LoginForm onSubmit={methods.handleSubmit(loginUser)}/>
                }
                {methods.formState.errors?.password && <p>{methods.formState.errors.password.message}</p>}
                {methods.formState.errors?.username && <p>{methods.formState.errors.username.message}</p>}
                {methods.formState.errors?.newPassword && <p>{methods.formState.errors.username.newPassword}</p>}
            </FormProvider>
        </div>
    );
}

