import Container from "react-bootstrap/Container";


export default function AppointmentConfirmed() {

    return (
        <Container className="d-flex align-items-center h-100">
            <h2 className="mx-auto" style={{textAlign:"center"}}>Appointment created successfully!</h2>
        </Container>
    );
}