import {
    isRouteErrorResponse,
    json,
    redirect, useLoaderData,
    useNavigate,
    useNavigation,
    useParams,
    useRouteError
} from "react-router-dom";
import React, {useState} from "react";
import TimePicker from "../forms/formparts/TimePicker";
import Button from "react-bootstrap/Button";
import {
    Card,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle, Spinner,
    Stack
} from "react-bootstrap";
import {Form} from "react-router-dom";

export const rescheduleLoader = async ({params}) => {
    const dataResponse = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/reschedule`);
    if (!dataResponse.ok) {
        console.log("not ok");
        throw dataResponse;
    } else {
        return await dataResponse.json();
    }
}

export const rescheduleAction = async ({params, request}) => {
    let formData = await request.formData();

    let dataResponse = await fetch(`https://botanical.ink/api/appointments/${params.appointmentId}/reschedule`,
        {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({newStart: formData.get("newStart")}),
        });
    if (dataResponse.ok) {
        // if ok, just send them pop the stack to un-render the modal
        return redirect("..?action=reschedule&actionStatus=success");
    } else if (dataResponse.status === 422) {
        //TIME_UNAVAILABLE
        return json({
            errorMessage: "The requested time is no longer available. Please select a new one from the updated options."
        })
    } else {
        throw dataResponse;
    }
}

export default function ReschedulerV2() {

    const scheduleData = useLoaderData();
    console.log(scheduleData);

    const [selectedTime, setSelectedTime] = useState("");

    const navigation = useNavigation();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate("..");
    }

    return (
        <>
            <Modal
                centered
                show={true}
                onHide={() => console.log("hidded")}
                fullscreen="lg-down"
            >
                <ModalHeader
                    className=""
                >
                    <ModalTitle className="mx-auto">Reschedule Appointment</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <p className="small" >Appointments may be rescheduled twice</p>
                    <TimePicker
                        closed={scheduleData.closed}
                        rawDates={scheduleData.dates}
                        dateTimeObj={scheduleData.dateTimeMap}
                        tattooMinutes={scheduleData.tattooMinutes}
                        timeSelectedCallback={setSelectedTime}
                        calenderTitle="New Date"
                        timeTitle="New Time"
                    />
                </ModalBody>
                <Form method="post">
                    <ModalFooter className="d-flex">
                        <Stack className="w-100" direction="horizontal" gap={3}>
                            <Button
                                className="w-50"
                                variant="secondary"
                                type={"button"}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <input name="newStart" type="hidden" value={selectedTime} className=""/>
                            <Button
                                className="w-50"
                                variant="primary"
                                type={"submit"}
                                disabled={selectedTime === "" || navigation.state !== "idle"}>
                                {navigation.state === "idle" && <span>Submit</span>}
                                {navigation.state === "submitting" &&<span><Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>Submitting</span>}
                                {navigation.state === "loading" &&<span><Spinner size="sm" role="status" aria-hidden="true" className="me-1"/>Loading</span>}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </Form>
            </Modal>
        </>


    );
}

const relevantStatusCodes = [400, 403, 404, 422];

export function RescheduleErrorBoundary() {
    const error = useRouteError();
    console.log(error);
    if (isRouteErrorResponse(error)) {
        if (relevantStatusCodes.includes(error.status)) {
            return (
                <p>{error.data.message}</p>
            );
        } else {
            return (
                <>
                    <p>Please try again later</p>
                    <p>An error occurred on the server while processing your reschedule request.</p>
                </>
            );
        }
    }

    // rethrow to let the parent error boundary handle it
    // when it's not a special case for this route
    throw error;
}