import accordionData from './AccordionData'
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'react-bootstrap';
import "../assets/styles/accordion.css"

export function FaqContent() {
    return (
        <Accordion className="accordion-outer">
            {
                accordionData.map((item) => (
                    <AccordionItem className="accordion-outer" key={item.id} eventKey={item.id}>

                        <AccordionHeader className="accordion-outer" >{item.title}</AccordionHeader>
                        <AccordionBody className="accordion-outer p-0">
                            <Accordion className="accordion-inner" flush>
                                {item.children.map((innerItem) => (
                                    <AccordionItem className="accordion-inner" key={innerItem.id} eventKey={innerItem.id}>
                                        <AccordionHeader className="accordion-inner" >{innerItem.title}</AccordionHeader>
                                        <AccordionBody className="accordion-inner">
                                            {innerItem.children[0].title}
                                        </AccordionBody>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </AccordionBody>
                    </AccordionItem>
                ))
            }
        </Accordion>
    );
}
