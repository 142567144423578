import '../assets/styles/Footer.css'
import {
    FaTiktok,
    FaInstagram
} from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import {Stack} from "react-bootstrap";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <Container fluid className='d-flex flex-column footer-container pt-3'>
            <Container className="d-flex flex-column mx-auto">
                {/* Footer social links */}
                <Stack className="mx-auto mb-3" direction="horizontal" gap={3}>
                    <a href="https://www.instagram.com/botanicalink/">
                        <FaInstagram  size={40} />
                    </a>
                </Stack>
                <address className='mx-auto text-center small'>664 N 9th St, Boise, ID 83702</address>
                <iframe
                    title="findUs"
                    style={{
                        title: "findUs"
                    }}
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4084.7175984557366!2d-116.19938093082709!3d43.620458602502744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54aef99cde6c2017%3A0x43a14b89d776fbca!2sBotanical%20Ink%20LLC!5e0!3m2!1sen!2sus!4v1703784675460!5m2!1sen!2sus"
                    allowFullScreen
                    loading="lazy"
                    className="mb-3"
                >
                </iframe>
                <p className='text-center mx-auto mb-3 border border-secondary p-2 rounded-3'>We acknowledge that the land we call
                    Boise, Idaho and Ada County, where our tattoo shop is located, are stolen lands that are the
                    traditional lands of the Shoshone-Bannock tribes.
                </p>
                <p className='small text-center mx-auto mb-3'>&copy; Copyright {currentYear},
                    Botanical Ink LLC
                </p>
            </Container>
        </Container>
    );
};

export default Footer;