import {json, Link, Outlet, useLoaderData} from "react-router-dom";
import Container from "react-bootstrap/Container";
import React, {ListGroup, ListGroupItem, ToastContainer} from "react-bootstrap";
import {formatTime} from "../forms/formparts/TimePicker";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/AuthContext";
import {SuccessToast} from "../util/Toasts";
import Button from "react-bootstrap/Button";


export const manageLoader = async ({params, request}) => {
    const url = new URL(request.url);
    const action = url.searchParams.get("action");
    const actionStatus = url.searchParams.get("actionStatus");

    const dataResponse = await fetch(`https://botanical.ink/api/appointments?` + new URLSearchParams({
        appointmentId: params.appointmentId,
    }));

    if (dataResponse.ok) {
        let dataJson = await dataResponse.json();
        return json({
            ...dataJson,
            action: action,
            actionStatus: actionStatus,
        })
    } else {
        return dataResponse;
    }
}

const createReferenceImageObj = (url) => {
    let imageName = extractFilename(url);
    return {
        original: url,
        thumbnail: url,
        originalTitle: imageName,
        thumbnailTitle: imageName,
    };
}

const extractFilename = (url) => {
    let splitArr = url.split('?');
    let stuff = splitArr[0].split('/');
    return stuff[stuff.length - 1];
}

const processReferenceImages = (references) => {
    if (!references) {return []}
    return references.map((url, index) => (
        createReferenceImageObj(url)
    ))
}

export default function AppointmentMain() {
    // const {appointmentId} = useParams();
    const loaderData = useLoaderData();
    console.log(loaderData);

    const {action, actionStatus} = loaderData;

    useEffect(() => {
        if (action && action === "reschedule"
            && actionStatus && actionStatus === "success") {
            handleShowSuccessToast("Appointment Rescheduled")
        }
    }, [action, actionStatus]);

    const {
        // artist,
        artistFullName,
        start,
        // end,
        tattooDurationMinutes,
        // bufferMinutes,
        hourlyRate,
        touchup,
        primaryClient,
        // secondaryClient,
        tattooDetails,
        deposit,
        outsideRescheduleWindow,
        underRescheduleLimit,
        // message
    } = loaderData;

    const [failedImageUrls, setFailedImageUrls] = useState([]);
    const [referenceImages, setReferenceImages] = useState(processReferenceImages(tattooDetails["tattoo0"]["references"]));

    const totalCost = (tattooDurationMinutes / 60) * hourlyRate;
    let depositPaid = deposit ? deposit : 0;
    const costAfterDeposit = totalCost - depositPaid;

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Success!");
    const handleShowSuccessToast = (message) => {
        setSuccessMessage(message);
        setShowSuccessToast(true);
    }

    const handlHideSuccessToast = () => {
        setShowSuccessToast(false);
    }

    // move reschedule toast handling here. There will be more than one "success" toast soon
    // const handleRescheduleSuccess = () => {
    //
    // }

    const canReschedule = () => {
        return outsideRescheduleWindow && underRescheduleLimit;
    }

    const getRescheduleDisabledMessage = () => {
        if (!outsideRescheduleWindow) return "Appointments may not be rescheduled within 72 hours of the scheduled time.";
        else if (!underRescheduleLimit) return "Appointment has been rescheduled the maximum number of times (2)";
        else return "";

    }


    return (
        <>
            <SuccessToast
                show={showSuccessToast}
                onClose={handlHideSuccessToast}
                children={successMessage}
                delay={5000}
            />

            <Container className="bg-primary d-flex flex-column rounded-3 mb-3">
                <Container id="schedule-details"
                           className="lh-sm d-flex flex-column align-items-center text-black bg-light rounded-3 my-2">
                    <h4 className="text-black mx-auto mt-2">
                        Schedule Details
                    </h4>
                    <ListGroup className="mb-3">
                        <ListGroupItem><strong>Artist: </strong>{artistFullName}</ListGroupItem>
                        <ListGroupItem><strong>Date: </strong>{start ? start.split('T')[0] : "ON HOLD"}</ListGroupItem>
                        <ListGroupItem><strong>Time: </strong>{start ? formatTime(start, tattooDurationMinutes) : "ON HOLD"}
                        </ListGroupItem>
                        <ListGroupItem><strong>Length: </strong>{tattooDurationMinutes + " minutes"}</ListGroupItem>
                    </ListGroup>

                    {/*  Reschedule goes here!  */}
                    {canReschedule()
                        ?
                        <>
                            <Link className="btn btn-primary mb-3" to={"reschedule"}>Reschedule</Link>
                            <Outlet/>
                        </>
                        :
                        <>
                            <Button className="btn-primary mb-1" disabled={true}>Reschedule</Button>
                            <p className="lh-sm small">{getRescheduleDisabledMessage()}</p>
                        </>
                    }

                </Container>
            </Container>

            <Container className="bg-primary d-flex flex-column rounded-3 mb-3">
                <Container id="cost-details"
                           className="lh-sm d-flex flex-column align-items-center text-black bg-light rounded-3 pb-3 my-2">
                    <h4 className="text-black mx-auto mt-2">
                        Cost Details
                    </h4>
                    <CostContainer
                        touchup={touchup}
                        hourlyRate={hourlyRate}
                        tattooDurationMinutes={tattooDurationMinutes}
                        totalCost={totalCost}
                        depositPaid={depositPaid}
                        costAfterDeposit={costAfterDeposit}
                    />
                </Container>
            </Container>

            <Container className="bg-primary d-flex flex-column flex-grow-1 rounded-3 mb-3">
                <Container id="client-details"
                           className="lh-sm d-flex flex-column align-items-center text-black bg-light rounded-3 pb-3 my-2">
                    <h4 className="text-black mx-auto mt-2">
                        Client Details
                    </h4>
                    <ListGroup>
                        {primaryClient.preferredName &&
                            <ListGroupItem>
                                <strong>Preferred Name: </strong>{primaryClient.preferredName}
                            </ListGroupItem>
                        }
                        <ListGroupItem>
                            <strong>Legal Name: </strong>{primaryClient.firstName + " " + primaryClient.lastName}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Email: </strong>{primaryClient.email}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Phone: </strong>{primaryClient.phone}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Allergies: </strong>{primaryClient.allergies || "None"}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Accomodations: </strong>{primaryClient.accommodations || "None"}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>First Tattoo: </strong>{primaryClient.firstTattoo ? "true" : "false"}
                        </ListGroupItem>
                    </ListGroup>
                </Container>
            </Container>

            <Container className="bg-primary d-flex flex-column flex-grow-1 rounded-3 mb-3">
                <Container id="tattoo-details"
                           className="lh-sm d-flex flex-column align-items-center text-black bg-light rounded-3 pb-3 my-2">
                    <h4 className="text-black mx-auto mt-2">
                        Tattoo Details
                    </h4>
                    <ListGroup>
                        <ListGroupItem>
                            <strong>Type: </strong>{tattooDetails["tattoo0"].type}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Style: </strong>{tattooDetails["tattoo0"].style}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Length: </strong>{tattooDetails["tattoo0"].length}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Width: </strong>{tattooDetails["tattoo0"].width}
                        </ListGroupItem>
                        <ListGroupItem>
                            <strong>Description: </strong></ListGroupItem>
                        <ListGroupItem>
                            {tattooDetails["tattoo0"].description}</ListGroupItem>
                        <ListGroupItem>
                            <strong>Placement: </strong></ListGroupItem>
                        <ListGroupItem>
                            {tattooDetails["tattoo0"].placement}</ListGroupItem>
                        {tattooDetails.length > 1 &&
                            <ListGroupItem>
                                <strong>Duration Minutes: </strong>{tattooDetails["tattoo0"].durationMinutes}
                            </ListGroupItem>
                        }
                    </ListGroup>
                    <h4 className="text-black mx-auto my-3">
                        Reference Images
                    </h4>
                    <Container className="w-50">
                        <ImageGallery
                            className="d-inline-flex mx-auto"
                            items={referenceImages}
                            onImageError={(event) => {
                                console.log("imageLoadError");
                                console.log(event);

                                setReferenceImages(
                                    referenceImages.filter(image => image.original !== event.target.src)
                                );

                                setFailedImageUrls(
                                    [
                                        ...failedImageUrls,
                                        {
                                            title: event.target.title,
                                            src: event.target.src
                                        }
                                    ]
                                );
                            }}
                        />
                    </Container>
                    {Array.isArray(failedImageUrls) && failedImageUrls.length > 0 &&
                        <Container className="d-flex flex-column">
                            <h4 className="mx-auto mt-3">Non-Image Files</h4>
                            <ListGroup className="mx-auto">
                                {failedImageUrls.map((url, index) => (
                                    <ListGroupItem>
                                        <a href={url.src} key={url.title + index}>{url.title}</a>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </Container>
                    }
                    {/*{referenceImages &&*/}
                    {/*    <Container className="d-flex flex-column">*/}
                    {/*        <h5 className="mx-auto mt-3" >Download links</h5>*/}
                    {/*        <ListGroup className="mx-auto">*/}
                    {/*            {referenceImages.map((image, index) => (*/}
                    {/*                <ListGroupItem>*/}
                    {/*                    <a href={image.original} key={image.originalTitle + index}>{image.originalTitle}</a>*/}
                    {/*                </ListGroupItem>*/}
                    {/*            ))}*/}
                    {/*        </ListGroup>*/}
                    {/*    </Container>*/}
                    {/*}*/}
                </Container>
            </Container>

        </>
    );
}

function CostContainer({touchup, hourlyRate, tattooDurationMinutes, totalCost, depositPaid, costAfterDeposit}) {
    if (touchup) {
        return (
            <>
                <p className="small lh-sm mb-2" >Touchup appointments are always free of charge.</p>
                <ListGroup>
                    <ListGroupItem><strong>Amount Due: </strong>{"$0"}</ListGroupItem>
                </ListGroup>
            </>
        );
    }
    return (
        <>
            <p className="small lh-sm">Estimated from the provided details. Final amount due is calculated at the end of your
                appointment.</p>
            <ListGroup>
                <ListGroupItem><strong>Hourly Rate: </strong>{"$" + hourlyRate}</ListGroupItem>
                <ListGroupItem><strong>Hours: </strong>{tattooDurationMinutes / 60}</ListGroupItem>
                <ListGroupItem><strong>Total Cost: </strong>{"$" + totalCost}</ListGroupItem>
                <ListGroupItem><strong>Deposit Paid: </strong>{"- $" + depositPaid}</ListGroupItem>
                <ListGroupItem><strong>Remaining: </strong>{"$" + costAfterDeposit}</ListGroupItem>
            </ListGroup>
        </>
    );
}