import Image from "react-bootstrap/Image";
import React, {FormGroup, Stack} from "react-bootstrap";
import erikaProfilePic from "../../../assets/images/erika-pose-1.jpg";
import calistaProfilePic from "../../../assets/images/cali-pose-1.jpg";
import {useFormContext} from "react-hook-form";


const artists = [
    {
        name: "erika",
        fullname: "Erika Holmberg",
        title: "Fine Line Tattoo Artist",
        picture: erikaProfilePic
    },
    {
        name: "calista",
        fullname: "Calista Doran",
        title: "Fine Line Tattoo Artist",
        picture: calistaProfilePic
    }
];

export default function ArtistSelect({setSelectedArtist}) {
    const {
        register,
    } = useFormContext();

    return (
        <FormGroup className={"d-flex flex-column flex-lg-row mb-3 column-gap-3"} controlId="artist">
            {artists.map((artistStruc) => (
                <label
                    key={artistStruc.name + "label"}
                    className="artist-select d-flex flex-grow-1 flex-column flex-lg-row justify-content-center p-2">
                    <input
                        className="artist-select-radio"
                        type="radio"
                        key={artistStruc.name}
                        id={artistStruc.name}
                        value={artistStruc.name}
                        name="artist"
                        {...register("artist")}
                        onChange={(event) => setSelectedArtist(event.target.value)}
                    />
                    <Image
                        key={artistStruc.name + "img"}
                        src={artistStruc.picture}
                        alt={artistStruc.fullname}
                        height={"100px"}
                        width={"100px"}
                        className="rounded-circle m-auto"
                    />
                    <Stack className="m-auto" style={{minWidth: "200px"}}>
                        <div className="h4 mx-auto" key={artistStruc.name + "full"}>{artistStruc.fullname}</div>
                        <div className="mx-auto" key={artistStruc.name + "tit"}>{artistStruc.title}</div>
                    </Stack>
                </label>
            ))}
        </FormGroup>
    );
}