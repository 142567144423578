import Container from "react-bootstrap/Container";
import Calendar from "react-calendar";
import {Stack, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import React, {useCallback, useState} from "react";
import {lightFormat} from "date-fns";
import Button from "react-bootstrap/Button";

//"2024-01-31T11:45:00"
export function formatTime(startDateTimeStr, tattooMinutes) {
    //"11:45:00"
    let startTimeStr = startDateTimeStr.split('T')[1];
    //["11","45","00"]
    let splitTime = startTimeStr.split(':');

    let startHr = parseInt(splitTime[0]);
    let startMin = parseInt(splitTime[1]);

    let endHr = startHr + Math.floor(tattooMinutes / 60);
    let endMin = startMin + (tattooMinutes % 60);

    let startSuffix = startHr >= 12 ? "PM" : "AM";
    let endSuffix = endHr >= 12 ? "PM" : "AM";

    return ("" + ((startHr + 11) % 12 + 1)).padStart(2, '0') + ":" + ("" + startMin).padStart(2, '0') + startSuffix
        + " to " +
        ("" + ((endHr + 11) % 12 + 1)).padStart(2, '0') + ":" + ("" + endMin).padStart(2, '0') + endSuffix;
}

const getDateTimeMap = (dateTimeObj) => {
    //convert object to map
    let convertedMap = new Map(Object.entries(dateTimeObj));
    // filter out any dates without times
    return new Map([...convertedMap].filter(([k, v]) => v.length > 0));
}

export default function TimePicker({
                                       closed,
                                       rawDates,
                                       dateTimeObj,
                                       tattooMinutes,
                                       timeSelectedCallback,
                                       calenderTitle = "Select Date",
                                       timeTitle = "Select Time"
                                   }) {

    //filter any empty dates that might have been returned
    const dateTimeMap = getDateTimeMap(dateTimeObj);
    const earliestDate = [...dateTimeMap.keys()].sort()[0];

    const [selectedDate, setSelectedDate] = useState(earliestDate);
    const [selectedTime, setSelectedTime] = useState("");

    // CREATE CALLBACKS
    const tileDisabled = useCallback(({date, view}) => {
        let dateString = date.toISOString().split('T')[0]
        if (view === 'month') {
            return ![...dateTimeMap.keys()].includes(dateString);
        }
    }, [dateTimeMap]);

    const tileClassName = useCallback(({date, view}) => {
        let dateString = date.toISOString().split('T')[0];
        if (view === 'month' && [...dateTimeMap.keys()].includes(dateString)) {
            return "date-has-times";
        }
    }, [dateTimeMap]);

    const handleDateClick = (value) => {
        let valueString = lightFormat(value, "yyyy-MM-dd");
        if (selectedDate !== valueString) {
            setSelectedDate(valueString);
            setSelectedTime("");
            timeSelectedCallback("");
        }
    }

    const handleTimeClick = (event) => {
        setSelectedTime(event.target.value);
        timeSelectedCallback(event.target.value);
    }

    return (
        <>
            <Container
                className="bg-success d-flex flex-column justify-content-center text-light mb-3 rounded-3 p-2">
                <div className={"h4 mx-auto mb-3"}>
                    {calenderTitle}
                </div>
                <div className="mx-auto mb-3">
                    <Calendar
                        defaultValue={new Date(earliestDate + "T00:00:00")}
                        onClickDay={handleDateClick}
                        minDate={new Date(earliestDate + "T00:00:00")}
                        maxDate={new Date(closed)}
                        minDetail={"month"}
                        tileDisabled={tileDisabled}
                        tileClassName={tileClassName}
                        next2Label={null}
                        prev2Label={null}
                    />
                </div>
            </Container>
            <Container className="bg-success text-light mb-1 rounded-3">
                <p className={"h4 mx-auto"}>{timeTitle}</p>
                <p className="lh-sm small">All times are displayed in Mountain Time (MT)</p>

                <Container
                    className={"d-flex mx-auto flex-column mb-3 rounded-3"}
                    // style={{
                    //     maxWidth: "350px",
                    //     minHeight: "30px"
                    // }}
                >
                    <Stack
                        className={"mb-3"}
                        gap={3}
                        direction="vertical"
                    >
                        {dateTimeMap.has(selectedDate) && dateTimeMap.get(selectedDate).map((dateTime) => (
                            <Button
                                type="button"
                                name={dateTime}
                                id={dateTime}
                                className="w-100 btn-outline-primary btn-secondary"
                                value={dateTime}
                                key={dateTime}
                                onClick={(event) => {
                                    handleTimeClick(event);
                                }}
                                active={selectedTime !== "" && selectedTime === dateTime}
                            >
                                {formatTime(dateTime, tattooMinutes)}
                            </Button>
                        ))
                        }
                    </Stack>
                </Container>
            </Container>
        </>
    );
}