import {createBrowserRouter, createRoutesFromElements, redirect, Route, RouterProvider} from "react-router-dom";
import PublicLayout from "./components/PublicLayout";
import {TempHome} from "./components/temp/UnderConstruction";
import Artists from "./components/Artists";
import FAQ from "./components/FAQ";
import AdminLogin from "./components/auth/AdminLogin";

import React from "react";
import {AppointmentLayout} from "./components/auth/AppointmentLayout";
import AuthLayout from "./components/auth/AuthLayout";
import AppointmentCreate, {createLoader} from "./components/forms/AppointmentCreate";
import AppointmentSchedule, {
    scheduleAction,
    scheduleLoaderV2
} from "./components/forms/AppointmentSchedule";
import ErrorPage from "./components/errors/ErrorPage";
import AppointmentConfirmed from "./components/forms/AppointmentConfirmed";
import AppointmentPayment, {paymentAction} from "./components/forms/AppointmentPayment";
import AppointmentNew, {appointmentNewLoader} from "./components/forms/AppointmentNew";
import AppointmentMain, {manageLoader} from "./components/pages/AppointmentMain";
import ReschedulerV2, {
    rescheduleAction,
    RescheduleErrorBoundary,
    rescheduleLoader
} from "./components/pages/ReschedulerV2";
import {ProtectedLayout} from "./components/auth/ProtectedLayout";
import {adminLoader, ArtistHome} from "./components/admin/ArtistHome";
import AppointmentCreateV2, {createActionV2, createLoaderV2} from "./components/forms/createV2/AppointmentCreateV2";
import {RouteAuth} from "./components/auth/RouteAuth";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<PublicLayout/>}
            errorElement={<ErrorPage/>}
        >
            <Route index element={<TempHome/>}/>
            <Route
                path="artist/erika"
                element={<Artists artistName="erika"/>}
            />
            <Route
                path="artist/calista"
                element={<Artists artistName="calista"/>}
            />
            <Route
                path="faq"
                element={<FAQ/>}
            />
            <Route path="appointment" element={<AppointmentLayout/>}>
                <Route index path="create"
                       element={<AppointmentCreate/>}
                       errorElement={<ErrorPage/>}
                       loader={createLoader}
                />
                <Route path=":appointmentId"
                       element={<AppointmentNew/>}
                       errorElement={<ErrorPage/>}
                       loader={appointmentNewLoader}
                >
                    <Route index path="schedule"
                           element={<AppointmentSchedule/>}
                           loader={scheduleLoaderV2}
                           action={scheduleAction}
                    />
                    <Route path="payment"
                           element={<AppointmentPayment/>}
                           action={paymentAction}/>
                    <Route path="confirmed" element={<AppointmentConfirmed/>}/>
                </Route>
                <Route path="manage/:appointmentId"
                       element={<AppointmentMain/>}
                       loader={manageLoader}
                >
                    <Route path="reschedule"
                           element={<ReschedulerV2/>}
                           errorElement={<RescheduleErrorBoundary/>}
                           loader={rescheduleLoader}
                           action={rescheduleAction}
                    />
                </Route>
            </Route>

            <Route
                element={<AuthLayout/>}
            >
                <Route
                    path="login"
                    element={<AdminLogin/>}
                />
                <Route
                    path="admin"
                    id={"admin"}
                    loader={adminLoader(RouteAuth.getIdToken)}
                    element={<ProtectedLayout/>}>

                    <Route
                        index
                        element={<ArtistHome/>}
                    />
                    <Route
                        path="v2/create"
                        element={<AppointmentCreateV2/>}
                        loader={createLoaderV2}
                        action={createActionV2}
                    />
                </Route>
            </Route>
        </Route>
    )
);

export default function App() {
    return (
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>}/>
    );
}


